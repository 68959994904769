import React, { Component } from "react"
import { Link } from "gatsby"

// // MetisMenu
// import MetisMenu from "metismenujs";
// import { withRouter } from "react-router-dom";
// import { Link } from "react-router-dom";

// //i18n
// import { withNamespaces } from 'react-i18next';

// class SidebarContent extends Component {

//     constructor(props) {
//         super(props);
//         state = {
//         };
//     }

//     componentDidMount() {
//         initMenu();
//     }

//     componentDidUpdate(prevProps) {
//         if (props.type !== prevProps.type) {
//             initMenu();
//         }
//     }

//     initMenu() {
//             new MetisMenu("#side-menu");

//             var matchingMenuItem = null;
//             var ul = document.getElementById("side-menu");
//             var items = ul.getElementsByTagName("a");
//             for (var i = 0; i < items.length; ++i) {
//                 if (props.location.pathname === items[i].pathname) {
//                     matchingMenuItem = items[i];
//                     break;
//                 }
//             }
//             if (matchingMenuItem) {
//                 activateParentDropdown(matchingMenuItem);
//             }
//     }

//     activateParentDropdown = item => {
//         item.classList.add("active");
//         const parent = item.parentElement;

//         if (parent) {
//             parent.classList.add("mm-active");
//             const parent2 = parent.parentElement;

//             if (parent2) {
//                 parent2.classList.add("mm-show");

//                 const parent3 = parent2.parentElement;

//                 if (parent3) {
//                     parent3.classList.add("mm-active"); // li
//                     parent3.childNodes[0].classList.add("mm-active"); //a
//                     const parent4 = parent3.parentElement;
//                     if (parent4) {
//                         parent4.classList.add("mm-active");
//                     }
//                 }
//             }
//             return false;
//         }
//         return false;
//     };

export default function SidebarContent(props) {
  const selectedProject =localStorage.getItem('selected_project')
  const parsedProject = JSON.parse(selectedProject)

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{parsedProject.label}</li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="bx bx-home-circle"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard" className="waves-effect">
            <img src="/images/postgresql.svg" height="20" className="mr-2"/>
              <span>PostgreSQL</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard" className="waves-effect">
            <img src="/images/hasura-icon3.svg" height="20" className="mr-2"/>
              <span>Hasura</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard" className="waves-effect">
            <i className="mdi mdi-graphql"></i>
            {/* <img src="/images/graphql.svg" height="20" className="mr-2"/> */}
              <span>GraphQL API</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="bx bx-group"></i>
              <span>Auth</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="bx bx-store"></i>
              <span>Storage</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="bx bx-cog"></i>
              <span>Settings</span>
            </Link>
          </li>

          <li className="menu-title">Support</li>
          <li>
            <Link to="/dashboard" className=" waves-effect">
              <i className="bx bx-support"></i>
              <span>Support</span>
            </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}
