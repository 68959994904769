import React, { useState, useEffect } from "react";
import ProfileMenu from "./ProfileMenu"
import {Link} from "gatsby"

// import { connect } from "react-redux";
// import { Row, Col } from "reactstrap";
import Select from "react-select"

// import { Link } from "react-router-dom";

// // Reactstrap
// import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// // Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import megamenuImg from "../../assets/images/megamenu-img.png";
// import logo from "../../assets/images/logo.svg";
// import logoLightPng from "../../assets/images/logo-light.png";
// import logoLightSvg from "../../assets/images/logo-light.svg";
// import logoDark from "../../assets/images/logo-dark.png";

// // import images
// import github from "../../assets/images/brands/github.png";
// import bitbucket from "../../assets/images/brands/bitbucket.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import slack from "../../assets/images/brands/slack.png";

// //i18n
// import { withNamespaces } from 'react-i18next';

// // Redux Store
// import { toggleRightSidebar } from "../../store/actions";

// class Header extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isSearch: false
//     };
//     this.toggleMenu = this.toggleMenu.bind(this);
//     this.toggleRightbar = this.toggleRightbar.bind(this);
//     this.toggleFullscreen = this.toggleFullscreen.bind(this);
//   }
//   /**
//    * Toggle sidebar
//    */
//   toggleMenu() {
//     this.props.toggleMenuCallback();
//   }

//   /**
//    * Toggles the sidebar
//    */
//   toggleRightbar() {
//     this.props.toggleRightSidebar();
//   }


//   toggleFullscreen() {
//     if (
//       !document.fullscreenElement &&
//       /* alternative standard method */ !document.mozFullScreenElement &&
//       !document.webkitFullscreenElement
//     ) {
//       // current working methods
//       if (document.documentElement.requestFullscreen) {
//         document.documentElement.requestFullscreen();
//       } else if (document.documentElement.mozRequestFullScreen) {
//         document.documentElement.mozRequestFullScreen();
//       } else if (document.documentElement.webkitRequestFullscreen) {
//         document.documentElement.webkitRequestFullscreen(
//           Element.ALLOW_KEYBOARD_INPUT
//         );
//       }
//     } else {
//       if (document.cancelFullScreen) {
//         document.cancelFullScreen();
//       } else if (document.mozCancelFullScreen) {
//         document.mozCancelFullScreen();
//       } else if (document.webkitCancelFullScreen) {
//         document.webkitCancelFullScreen();
//       }
//     }
//   }

export default function  Header({projects, ...props}) {
  const [selectedProject, setSelectedProject] = useState()
  const [isCollapsed, setIsCollapsed] = useState(false)

  useEffect(()=>{
    const project =localStorage.getItem('selected_project')
    const parsedProject = JSON.parse(project)
    setSelectedProject(parsedProject)
    
  }, [])

  const options = projects.map((value, i)=>({label:value.project_name, value:value.id}))
  
   const optionGroup = [
    {
      label: "Projects",
      options
    }
  ];

  const handleSelectProject = (project) =>{
    localStorage.setItem("selected_project", JSON.stringify(project))
    setSelectedProject(project)
    // window.location.reload()

  }

  const toggleMenu = ()=>{
    if(!isCollapsed){
    document.body.classList.add("vertical-collpsed");
    setIsCollapsed(true)
  }else{
    document.body.classList.remove("vertical-collpsed");
    setIsCollapsed(false)
  }
  }
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src="/images/paraffin-logo.png" alt="" height="42" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src="/images/paraffin-logo.png" alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src="/images/paraffin-logo.png" alt="" height="50" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                onClick={toggleMenu}
                className="btn btn-sm px-3 font-size-12 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAArUlEQVRoQ+2WQQrAIBDE9P+Prj+QgTAs0vTsrLuJYvd6/NuP978cYNqgBjQACXiEIEAc1wBGCAv8wsAHIdH4FXJiwAGgAg1AgN14cge6HcDqDgAB4rgGMEJYIDHgQ9aErAFIN4njX4lkk7E1yREaay7Z2AESSs01GmjSTWonBnyJE5KXNfgd0MC0Abh/N55c4m4HsLoDQIA4rgGMEBbQAASI4xrACGEBDUCAOH4Al9kMMZG0RokAAAAASUVORK5CYII="
                  height="30"
                />
              </button>
              <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <Select
                    className="select-product"
                    value={selectedProject}
                    onChange={handleSelectProject}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    placeholder="Select Project"
                  />
                </div>
              </form>
            </div>

            <div>
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    )
  }



