import React from "react"
import Layout from "../components/VerticalLayout"
import SEO from "../components/seo"
import {GET_PROJECTS} from "./index"
import {useQuery} from "@apollo/react-hooks"

export default function Dashboard(props){
    const { loading, error, data } = useQuery(GET_PROJECTS)

   

    return (
        <React.Fragment>
            <SEO/>
        <Layout loading={loading} error={error} projects={data}/>

        </React.Fragment>
    )
}