import React, { Component, useEffect } from "react"

// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import { } from "../../store/actions";

//Simple bar
// import SimpleBar from "simplebar-react";

// //i18n
// import { withNamespaces } from 'react-i18next';
import SidebarContent from "./SidebarContent"

// class Sidebar extends Component {
//     constructor(props) {
//         super(props);
//         state = {
//         };
//     }

export default function Sidebar(props) {
  useEffect(() => {
    if (document.body) {
      document.body.setAttribute("data-sidebar", "colored")
      document.body.setAttribute("data-topbar", "light")
     document.body.setAttribute("data-keep-enlarged", "true")
    }
  }, [])
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <SidebarContent />
      </div>
    </React.Fragment>
  )
}
// }

// const mapStatetoProps = state => {
//     return {
//         layout: state.Layout
//     };
// };
// export default connect(mapStatetoProps, {})(withRouter(withNamespaces()(Sidebar)));
