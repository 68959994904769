import React, { Component, useLayoutEffect } from "react";

// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import {
//   changeLayout,
//   changeSidebarTheme,
//   changeSidebarType,
//   toggleRightSidebar,
//   changeTopbarTheme,
//   changeLayoutWidth
// } from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
// import Rightbar from "../CommonForBoth/Rightbar";

// class Layout extends Component {
//   constructor(props) {
//     super(props);
//     state = {
//       isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
//     };
//     toggleMenuCallback = toggleMenuCallback.bind(this);
//     toggleRightSidebar = toggleRightSidebar.bind(this);
//   }

//   toggleRightSidebar() {
//     props.toggleRightSidebar();
//   }

//   capitalizeFirstLetter = string => {
//     return string.charAt(1).toUpperCase() + string.slice(2);
//   };

//   componentDidMount() {

//     if (props.isPreloader === true) {
//       document.getElementById('preloader').style.display = "block";
//       document.getElementById('status').style.display = "block";

//       setTimeout(function () {

//         document.getElementById('preloader').style.display = "none";
//         document.getElementById('status').style.display = "none";

//       }, 2500);
//     }
//     else {
//       document.getElementById('preloader').style.display = "none";
//       document.getElementById('status').style.display = "none";
//     }

//     // Scroll Top to 0
//     window.scrollTo(0, 0);
//     let currentage = capitalizeFirstLetter(props.location.pathname);

//     document.title =
//       currentage + " | Skote - Responsive Bootstrap 4 Admin Dashboard";
//     if (props.leftSideBarTheme) {
//       props.changeSidebarTheme(props.leftSideBarTheme);
//     }

//     if (props.layoutWidth) {
//       props.changeLayoutWidth(props.layoutWidth);
//     }

//     if (props.leftSideBarType) {
//       props.changeSidebarType(props.leftSideBarType);
//     }
//     if (props.topbarTheme) {
//       props.changeTopbarTheme(props.topbarTheme);
//     }

//     if (props.showRightSidebar) {
//       toggleRightSidebar();
//     }
//   }
//   toggleMenuCallback = () => {
//     if (props.leftSideBarType === "default") {
//       props.changeSidebarType("condensed", state.isMobile);
//     } else if (props.leftSideBarType === "condensed") {
//       props.changeSidebarType("default", state.isMobile);
//     }
//   };

export default function Layout ({loading, error, projects, ...props}) {
  useLayoutEffect(()=>{
    if(loading){
      document.getElementById('preloader').style.display = "block";
      document.getElementById('status').style.display = "block";
    
    }else{
      document.getElementById('preloader').style.display = "none";
      document.getElementById('status').style.display = "none";
    
    }

  }, [loading])



    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>

        {!loading && (
          <div id="layout-wrapper">
            <Header
              toggleMenuCallback={() => "toggleMenuCallback"}
              toggleRightSidebar={() => "toggleRightSidebar"}
              projects={projects.project}
            />
            <Sidebar
              theme={props.leftSideBarTheme || "dark"}
              type={props.leftSideBarType || "icon"}
              isMobile={true}
            />
            <div className="main-content">{props.children}</div>
            {/* <Footer /> */}
          </div>
        )}

        {/* <Rightbar /> */}
      </React.Fragment>
    )
  }



// const mapStatetoProps = state => {
//   return {
//     ...state.Layout
//   };
// };
// export default connect(mapStatetoProps, {
//   changeLayout,
//   changeSidebarTheme,
//   changeSidebarType,
//   toggleRightSidebar,
//   changeTopbarTheme,
//   changeLayoutWidth
// })(withRouter(Layout));

